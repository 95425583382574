<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text"> SNS 연결 설정 </span>
			</p>
			<a class="nav__btn nav__btn--map"> </a>
		</div>
	</nav>
	<!-- // nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content">
				<div class="row">
					<ul class="sns sns--border">
						<!-- [D] 최초 연결 시 -->
						<li>
							<a>
								<i class="icon icon-sns icon-kt"></i>
								<span class="sns__title">
									{{t('10425')}}
								</span>
							</a>
							<button type="button" class="btn btn-medium btn-confirm" @click="onClickLink('02')" v-show="snsLinked === undefined">
								{{t('10426')}}
							</button>
							<button type="button" class="btn btn-medium btn-primary purple" @click="onClickUnlink('02')" v-show="snsLinked === '02'">
								{{t('10427')}}
							</button>
						</li>
						<li>
							<a>
								<i class="icon icon-sns icon-naver"></i>
								<span class="sns__title">
									{{t('10428')}}
								</span>
							</a>
							<button type="button" class="btn btn-medium btn-confirm" @click="onClickLink('01')" v-show="snsLinked === undefined">
								{{t('10426')}}
							</button>
							<button type="button" class="btn btn-medium btn-primary purple" @click="onClickUnlink('01')" v-show="snsLinked === '01'">
								{{t('10427')}}
							</button>
						</li>
						<li v-if="isIOS">
							<a>
								<i class="icon icon-sns icon-apple"></i>
								<span class="sns__title">
									{{t('10429')}}
								</span>
							</a>
							<button type="button" class="btn btn-medium btn-confirm" @click="onClickLink('03')" v-show="snsLinked === undefined">
								{{t('10426')}}
							</button>
							<button type="button" class="btn btn-medium btn-primary purple" @click="onClickUnlink('03')" v-show="snsLinked === '03'">
								{{t('10427')}}
							</button>
						</li>
					</ul>
				</div>
			</section>
			<div id="naverIdLogin" style="display:none" ref="naverLoginButton"/>
			<div id="appleid-signin" style="display:none" ref="appleLoginButton"/>
			<!-- //content -->
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex";
import { computed, onMounted, onUnmounted, ref } from "vue";
import api from "@/urls/mmb01";
import uuidv4 from "@/utils/uuid";
import iOS from "@/utils/iOS";
import jwt_decode from "jwt-decode";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import { kakaoCallbackUrl, naverCallbackUrl, naverClientId, appleCallbackUrl, appleClientId } from "@/utils/OAuthLogin";
import ErrorAlert from "@/components/layers/Message_alert";

export default {
	components: {
		ErrorAlert,
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
    	const { t }= useI18n() //번역필수 모듈
		const openErrorAlert = ref(false)
		const userData = computed(() => store.state.userData);
		const snsLinked = ref(undefined); 

		const naverLoginButton = ref(null);
		const appleLoginButton = ref(null);

		const isIOS = ref(iOS());
		const email = route.query.email;
		const snsJoinFlag = route.query.snsJoinFlag;
		const snsCi = route.query.snsCi;

		//console.log(email, snsJoinFlag, snsCi);

		const onClickUnlink = async (sns_join_flag) => {
			const data = await api.getMmb0102({
				proc_cd: "09",
				mem_id: userData.value.mem_token,
				sns_join_flag: sns_join_flag,
			});

			if (data.data.Res_cd === "0000") {
				snsLinked.value = undefined;
				store.commit("SET_USERDATA", {userData: {
					...userData.value,
					sns_join_flag2: "",
				}});
				localStorage.removeItem("isSnsLink");
			} else {
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: data.data.Res_msg});
				openErrorAlert.value = true;
				//alert(data.data.Res_msg);
			}
		};
		/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/
		const linkSns = async (_email, snsJoinFlag, snsCi) => {
			const data = await api.getMmb0102({
				proc_cd: "01",
				mem_id: userData.value.mem_token,
				sns_join_flag: snsJoinFlag,
				sns_ci: snsCi
			});

			if (data.data.Res_cd === "0000") {
				snsLinked.value = snsJoinFlag;
				store.commit("SET_USERDATA", {userData: {
					...userData.value,
					sns_join_flag2: snsJoinFlag,
				}});
				localStorage.removeItem("isSnsLink");
			} else {
				alert(data.data.Res_msg);
				router.push("/home");
			}
		};

		const onClickLink = async (sns_join_flag) => {
			localStorage.setItem("isSnsLink", "true");
			switch(sns_join_flag){
				case "01":
					naverLogin();
					break;
				case "02":
					kakaoLogin();
					break;
				case "03":
					appleLogin();
					break;
			}
		};

		const appleLogin = () => {
			appleLoginButton.value.firstChild.click();
		}

		const naverLogin = () => {
			naverLoginButton.value.firstChild.click();
		}

		const kakaoLogin = () => {
			 window.Kakao.Auth.authorize({
      		redirectUri: kakaoCallbackUrl,
    	});
		};

		const createState = () => {
			const state = uuidv4();
			localStorage.setItem("state", state);

			return state;
		}

		const eventAppleIDSignInOnSuccess = (data) => {
			const appleData = jwt_decode(data.detail.authorization.id_token);
			linkSns(appleData.email, "03", appleData.sub);
		};

		const eventAppleIDSignInOnFailure = (error) => {
			console.log(error);
		};

		const goBack = () => {
			router.go(-1);
		};

		onMounted(() => {
			if (userData.value.sns_join_flag2.length > 0) {
				snsLinked.value = userData.value.sns_join_flag2;
			}

			// 기존 연동정보 받아오기 필요

			const naverLogin = new window.naver.LoginWithNaverId({
				clientId: naverClientId,
				callbackUrl: naverCallbackUrl,
    			loginButton: {color: "green", type: 2, height: 40}
			});
			naverLogin.init();

			window.AppleID.auth.init({
				clientId : appleClientId,
				scope : 'name email',
				redirectURI: appleCallbackUrl,
				state : createState(),
						usePopup: true
			});

			if(email !== undefined && snsJoinFlag !== undefined && snsCi !== undefined) {
				linkSns(email, snsJoinFlag, snsCi);
			}

			document.addEventListener('AppleIDSignInOnSuccess', eventAppleIDSignInOnSuccess);
			document.addEventListener('AppleIDSignInOnFailure', eventAppleIDSignInOnFailure);
		});

		onUnmounted(() =>{
			localStorage.removeItem("isSnsLink");
			document.removeEventListener('AppleIDSignInOnSuccess', eventAppleIDSignInOnSuccess);
			document.removeEventListener('AppleIDSignInOnFailure', eventAppleIDSignInOnFailure);
		});

		return {
			userData,
			snsLinked,
			onClickUnlink,
			onClickLink,
			naverLoginButton,
			appleLoginButton,
			goBack,			
			isIOS,
			t,
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
		};
	},
};
</script>>