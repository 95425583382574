import fetch from "./fetch";

const MMB01_01_URL = () => "/PC/WPC_MMB01_01";
const MMB01_02_URL = () => "/PC/WPC_MMB01_02";
const MMB01_10_URL = () => "/PC/WPC_MMB01_10";
const MMB01_21_URL = () => "/PC/WPC_MMB01_21";
const MMB01_URL = () => "/PC/WPC_MMB01";

export default {
  postMmb0101: fetch("POST", MMB01_01_URL),
  postMmb0110: fetch("POST", MMB01_10_URL),
  getMmb0102: fetch("GET", MMB01_02_URL),
  getMmb0121: fetch("GET", MMB01_21_URL),
  postMmb01: fetch("POST", MMB01_URL),
};
